export enum APP_ROUTES {
  HOME = '/',
  ON_BOARDING = '/onboarding',
  PAY_WALL = '/pay-wall',
  E404 = '*',
  PROFILE = '/profile',
  PERSONAL_INFO = '/personal-info',
  SUCCESS = '/success',
  CONFIRM = '/confirm',
  SUBSCRIPTIONS = '/subscriptions',
  PRODUCTS = '/products',
  LOGIN = '/login',
  REDIRECT = '/redirect',
  MAILING_DISABLE = '/mailing-disable',
  EXTRA = '/extra',
  SALE = '/sale',
  DISCOUNT = '/discount',
  CONFIRM_BUY = '/confirm-buy',
}
