import {
  CheckoutEventNames,
  CheckoutEventsStatus,
  CheckoutOpenOptions,
  Environments,
  Paddle as PaddleTypes,
  PaddleEventData,
} from '@paddle/paddle-js';

import { AppConfig } from 'config';

import { FirebaseService } from './remoteConfig';
import { PixelServices, PixelTrackEvents } from './pixelServices';
import { AuthStore } from 'stores/Auth';
import { ANALYTICAL_EVENTS, Amplitude } from './amplitude';
import { GA } from './ga';
import { purchaseEventPeriod } from 'pages/PaywallPage/components/PaymentSection/utils';
import { getPaddlePixelMarketingPrice } from 'utils/analytics';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  onReject: () => void;
}

const PaddleInt = window.Paddle as PaddleTypes;

export class Paddle {
  static inst = new Paddle();

  events: Props | undefined;

  applyEvents(events: Props) {
    this.events = events;
  }

  eventCallback(event: PaddleEventData) {
    switch (event.data?.status) {
      case CheckoutEventsStatus.COMPLETED:
        const product = event.data.items[0];

        console.log(JSON.stringify({ prod: product.billing_cycle }));

        const body = {
          platform: 'paddle',
          price_id: product.price_id,
          period: product.billing_cycle?.interval,
          value: product.recurring_totals?.total,
          trial_period: purchaseEventPeriod(
            product.trial_period?.interval ?? 'day',
            product.trial_period?.frequency ?? 1,
          ),
          trial_value: product.totals.total,
          email: JSON.parse((window as any).localStorage.getItem('authStore'))
            .email,
        };

        console.log(body);

        const pixelData = {
          value: getPaddlePixelMarketingPrice(
            product.billing_cycle?.interval!,
            product.billing_cycle?.frequency!,
          ),
          currency: event.data?.currency_code,
        };
        PixelServices.track(PixelTrackEvents.PURCHASE, pixelData);
        PixelServices.pinterestTrack(PixelTrackEvents.PINTEREST_CHECKOUT, {
          ...pixelData,
          order_id: AuthStore.views(e => e.token),
          order_quantity: 1,
        });
        PixelServices.tiktokTrack(PixelTrackEvents.PURCHASE, pixelData);
        PixelServices.snapTrack(PixelTrackEvents.SNAP_PURCHASE, {
          currency: pixelData.currency,
          price: pixelData.value,
        });
        Amplitude.logEvent(ANALYTICAL_EVENTS.PAYWALL_TYPE_SUBSCRIBE, {
          paymentType: 'paddle',
        });
        GA.purchase(body);
        Amplitude.logEvent(ANALYTICAL_EVENTS.PAYWALL_PURCHASE_SUBSCRIBE, body);

        this.events?.onSuccess?.();
        return;
      case CheckoutEventsStatus.canceled:
        this.events?.onReject?.();
        return;
    }
    if (event.name === CheckoutEventNames.CHECKOUT_CLOSED) {
      this.events?.onClose?.();
      return;
    }
  }

  init() {
    PaddleInt?.Environment.set(AppConfig.PADDLE_ENV as Environments);
    PaddleInt?.Setup({
      seller: AppConfig.PADDLE_SALLER,
      eventCallback: this.eventCallback.bind(this),
    });
  }

  openCheckout({
    id,
    email,
    customData,
    discountId,
  }: {
    id: string;
    email?: string;
    customData?: Record<string, string | number | boolean>;
    discountId?: string;
  }) {
    const params: CheckoutOpenOptions = {
      items: [{ priceId: id }],
      customData,
      settings: {
        successUrl: FirebaseService.page
          ? `${window.location.protocol}//${window.location.host}/${FirebaseService.page}/confirm`
          : `${window.location.protocol}//${window.location.host}/confirm`,
        displayMode: 'inline',
        frameTarget: 'checkout-container',
        frameInitialHeight: 450,
        frameStyle: `
        min-width: 312px; 
        max-width: 500px; 
        width: 100%; 
        background-color: white; 
        border: none; 
        align-self: center;
        justify-self: center; 
        overflow: scroll;
        height: 100%;
        `,
      },
    };
    if (email) {
      params.customer = { email };
    }
    if (discountId) {
      params.discountId = discountId;
    }
    PaddleInt?.Checkout.open(params);
  }

  closeCheckout() {
    PaddleInt?.Checkout.close();
  }
}
