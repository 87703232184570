import noop from 'lodash/noop';
import { observer } from 'mobx-react-lite';
import { sha256 } from 'js-sha256';
import { PaymentStates } from 'stores/Payment';
import { useMst } from 'stores/RootStore';

import { Amplitude, ANALYTICAL_EVENTS } from 'services/amplitude';
import { PixelServices, PixelTrackEvents } from 'services/pixelServices';
import { FirebaseService, SubscriptionTypeModes } from 'services/remoteConfig';

import { useRelativeNavigation } from 'hooks/useRelativeNavigation';

import { APP_ROUTES } from 'navigations/Routs';

import { AuthModule } from 'components/AuthModule/AuthModule';
import { ICross } from 'components/Icons/ICross';
import { Popup } from 'components/Popup';

import { AuthorizedView } from './components/AuthorizedView/AuthorizedView';

import { CloseButtonWrap, Container, Header } from './PaymentPopup.styles';
import { PageTypes } from 'types/types';

type Props = {
  onClosePopup?: () => void;
};

export const PaymentPopup = observer(({ onClosePopup }: Props) => {
  const { authStore, paymentStore } = useMst();
  const { push } = useRelativeNavigation();

  const loading = paymentStore.paymentState === PaymentStates.loading;
  const showPayment = paymentStore.paymentState === PaymentStates.initial;
  const isSuccessulPayment =
    paymentStore.paymentState === PaymentStates.success;

  const showClose = loading || showPayment;

  const headerTitle =
    authStore.isAuthorized && showPayment ? 'Select payment method' : '';

  const onClose = () => {
    if (onClosePopup) {
      onClosePopup();
    }

    if (
      paymentStore.subscriptionTypeMode !== SubscriptionTypeModes.discount &&
      !isSuccessulPayment
    ) {
      paymentStore.enableDiscount();
      if (
        FirebaseService.showDiscountPage &&
        FirebaseService.paywallType !== PageTypes.PAYWALL_MEN_LONG
      ) {
        push(APP_ROUTES.DISCOUNT);
      }
    }
  };

  const onClickLoginButton = () => {
    Amplitude.logEvent(ANALYTICAL_EVENTS.PAYWALL_EMAIL, {
      email: authStore.tempEmail,
    });
    authStore.applyEmail();
    PixelServices.pinterestTrack(PixelTrackEvents.PINTEREST_LOGIN, {
      em: [sha256(authStore.tempEmail)],
      user_data: {
        em: [sha256(authStore.tempEmail)],
      },
    });
  };

  Popup.setCloseHandler(onClose);

  const leftContent = () =>
    showClose && (
      <CloseButtonWrap onClick={Popup.close}>
        <ICross />
      </CloseButtonWrap>
    );

  return (
    <Container>
      {!loading && (
        <Header hideBottomLine leftContent={leftContent} title={headerTitle} />
      )}
      {authStore.isAuthorized ? (
        <AuthorizedView />
      ) : (
        <AuthModule
          onClickLoginButton={onClickLoginButton}
          autofocus
          title="Enter your email"
          loginButtonLabel="Continue"
          onSuccessCode={noop}
          variant="paywall"
        />
      )}
    </Container>
  );
});
