import { GENDERS } from 'types/types';

import { FirebaseService } from 'services/remoteConfig';

export enum FONTS {
  STEINBECK = 'Steinbeck',
  INTER = 'Inter',
}

export enum PaymentTypes {
  STRIPE = 'stripe',
  PAYPAL = 'paypal',
  PAYPRO = 'paypro',
  PADDLE = 'paddle',
}

const allPaymentTypesData = [
  { value: PaymentTypes.PAYPAL, cover: './pp-logo.svg' },
  { value: PaymentTypes.PAYPRO, cover: './visa-mc-logo.svg' },
  { value: PaymentTypes.STRIPE, cover: './visa-mc-logo.svg' },
];

export const getPaymentTypesData = (paymentProviders: PaymentTypes[]) => {
  return allPaymentTypesData.filter(({ value }) =>
    paymentProviders.includes(value),
  );
};

export const DISABLED_BUTTONS_DATA = [
  { src: '/stripe-icon.svg' },
  { src: '/ssl-icon.svg' },
];

export const APP_ID = '609479354570d15b2437c4dd';

export const termsLink = 'https://app.sensualcourse.com/docs/termsofuse.html';
export const privacyLink =
  'https://app.sensualcourse.com/docs/privacypolicy.html';

export const supportLink = 'mailto:support@sensesmedia.com';

export const appleStoreLink =
  'https://apps.apple.com/us/app/senses-better-sex-kegel/id1563697753?l=en';
export const googleStoreLink =
  'https://play.google.com/store/apps/details?id=com.senses.app';

const femaleAppStoreLink =
  'https://apps.apple.com/us/app/senses-connect-with-your-body/id1563697753?ppid=7b51cdfb-c005-40ab-9880-179b99e97edc';
const maleAppStoreLink =
  'https://apps.apple.com/us/app/senses-connect-with-your-body/id1563697753?ppid=68b24d42-2110-47ba-9dff-a52eaefcf035';

export const oneLinkStoreUrl = 'https://sensualcourse.onelink.me/nmz6/209d54ac';

export function getAppStoreLinkByGender(provider: string, gender: GENDERS) {
  if (provider === 'google') {
    return oneLinkStoreUrl;
  }

  switch (gender) {
    case GENDERS.FEMALE:
      return femaleAppStoreLink;
    case GENDERS.MALE:
      return maleAppStoreLink;
    default:
      if (FirebaseService.page.match(/(female)|(w(o|e)man)/)) {
        return femaleAppStoreLink;
      } else {
        return maleAppStoreLink;
      }
  }
}
