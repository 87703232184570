import { DATE_INTERVAL, IProductDescription } from 'types/types';

export function isPremium() {
  return window.location.host.includes('premium');
}

export function isRelations() {
  return window.location.host.includes('relations');
}

export function getPixelMarketingPrice(data: IProductDescription): number {
  const { interval, intervalCount, trial, updateToProduct } = data;
  if (isPremium()) {
    return trial.amount / 100;
  }

  if (
    interval === DATE_INTERVAL.DAY &&
    intervalCount === 7 &&
    updateToProduct
  ) {
    return 55;
  }

  if (interval === DATE_INTERVAL.DAY && intervalCount === 28) {
    return 80;
  }

  if (interval === DATE_INTERVAL.DAY && intervalCount === 84) {
    return 90;
  }

  if (
    (interval === DATE_INTERVAL.WEEK && intervalCount === 1) ||
    (interval === DATE_INTERVAL.DAY && intervalCount === 7)
  ) {
    return 47;
  } else if (interval === DATE_INTERVAL.MONTH && intervalCount === 3) {
    return 73;
  } else {
    return 54;
  }
}

export function getPaddlePixelMarketingPrice(
  interval: string,
  frequency: number,
): number {
  if (
    (interval === 'week' && frequency === 1) ||
    (interval === 'day' && frequency === 7)
  ) {
    return 47;
  } else if (interval === 'month' && frequency === 3) {
    return 73;
  } else {
    return 54;
  }
}
