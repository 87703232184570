import { flow, toGenerator, types } from 'mobx-state-tree';

import { EmailVerificationStatuses } from 'types/types';

import { Amplitude } from 'services/amplitude';
import { SensesSDK } from 'services/api/senses';
import { GA } from 'services/ga';

import { parseJwt } from 'utils/parsers';
import { isValideEmail } from 'utils/validations';

interface IChangeEmail {
  newEmail?: string;
  onSuccess?: () => void;
  onError?: () => void;
}

export const AuthStore = types
  .model('AuthStore', {
    email: '',
    tempEmail: '',
    errorMessage: '',
    isLoading: false,
    isError: false,
    confirmed: false,
    isVerifyingCode: false,
    token: '',
  })
  .actions(self => ({
    setToken(token: string) {
      self.token = token;
      SensesSDK.setAuthKey(token);
    },
    setIsVerifyingCode(value: boolean) {
      self.isVerifyingCode = value;
    },
    clearError() {
      self.isError = false;
      self.errorMessage = '';
    },
  }))
  .actions(self => ({
    applyEmail: flow(function* (onNewUserCallback?: () => void) {
      self.isLoading = true;
      try {
        const { result } = yield* toGenerator(SensesSDK.signup(self.tempEmail));

        switch (result?.status) {
          case EmailVerificationStatuses.NEW_USER:
          case EmailVerificationStatuses.ERROR:
            self.confirmed = false;
            self.setToken(result.jwtToken ?? '');
            onNewUserCallback?.();
            break;
          case EmailVerificationStatuses.EXISTING_USER:
            self.setToken('');
            self.setIsVerifyingCode(true);
            break;
          default:
            break;
        }

        GA.event('generate_lead', { email: self.tempEmail });

        self.email = self.tempEmail;
        Amplitude.setUserProperties({ Email: self.email });
      } catch (error) {
        console.log('Signup error: ', error);
      } finally {
        self.isLoading = false;
      }
    }),

    verifyCode: flow(function* (
      code: string,
      onSuccess: () => void,
      onError?: () => void,
    ) {
      self.isLoading = true;
      try {
        const response = yield* toGenerator(
          SensesSDK.verifyEmail(self.email, code),
        );

        self.confirmed = true;
        self.setToken(response.jwtToken ?? '');

        onSuccess();
      } catch (error: any) {
        console.error('verifyEmail error', error);
        self.isError = true;
        self.errorMessage = error?.message;
        onError?.();
      } finally {
        self.isLoading = false;
      }
    }),

    changeEmail: flow(function* ({
      newEmail = self.tempEmail,
      onSuccess,
      onError,
    }: IChangeEmail) {
      self.isLoading = true;
      try {
        const response = yield* toGenerator(SensesSDK.changeEmail(newEmail));

        if (response.jwtToken) {
          self.setToken(response.jwtToken);
        }
        self.email = newEmail;
        self.confirmed = true;

        onSuccess?.();
      } catch (error: any) {
        console.error('changeEmail error', error);
        self.isError = true;
        self.errorMessage = error?.message;
        onError?.();
      } finally {
        self.isLoading = false;
      }
    }),

    checkToken: flow(function* (newToken) {
      try {
        self.setToken(newToken);
        yield* toGenerator(SensesSDK.getAllSubscriptions());

        self.confirmed = true;

        return true;
      } catch (e) {
        self.setToken('');

        return false;
      }
    }),

    setTempEmail(value: string) {
      self.clearError();
      self.tempEmail = value;
    },

    setEmail(value: string) {
      self.email = value;
      self.tempEmail = value;
    },

    skipAuth() {
      self.email = '';
      self.tempEmail = '';
      self.isVerifyingCode = false;
    },
  }))
  .views(self => ({
    get canContinue() {
      return !!self.tempEmail && !self.isError && isValideEmail(self.tempEmail);
    },

    get isAuthorized() {
      return !!self.token;
    },

    get accountId() {
      const parsedToken = parseJwt(self.token);
      const { accountId } = parsedToken;

      return accountId ?? null;
    },
  }));
