import { cast, flow, toGenerator, types } from 'mobx-state-tree';

import {
  IAditionalProduct,
  ICancelStripeSubscriptionRequest,
  IProduct,
  IProductDescription,
  IProductMap,
  ISubscription,
  ISubscriptionWithIntervalDate,
  WebPaymentStatus,
} from 'types/types';

import { SensesSDK } from 'services/api/senses';
import {
  FirebaseService,
  SubscriptionType,
  SubscriptionTypeModes,
} from 'services/remoteConfig';
import { CardInfo, StripeService } from 'services/stripeService';

import {
  formatIntervalPrice,
  formatIntervalTitle,
  formatMenuSubscriptionTitle,
  formatProductsData,
  formatRenewsDate,
  formatTrialDateEnd,
} from 'utils/formaters';
import { convertObjWithIdRecursive } from 'utils/object/convertObjWithId';

import { PaymentTypes } from 'constants/common';
import { DEFAULT_SUBSCRIPTION_TYPE } from 'constants/firebase';
import { GA } from 'services/ga';
import { analitics } from 'services/analytics';

export enum PaymentStates {
  initial,
  success,
  error,
  loading,
  stripeLoading,
}

interface UserInfo {
  email: string;
  fbc?: string;
  fbp?: string;
}
type ICreateStripeSubscription = {
  cardPay?: CardInfo;
  userInfo: UserInfo;
  onSuccess: () => void;
  onError: () => void;
};

interface IPaymentStoreVolatile {
  /**
   * Products to present on paywall
   */
  products?: IProductMap;
  /**
   * Products by user subscriptions' localProductId to merge with subscriptions
   */
  subscriptionsProducts?: IProductMap;
  subscriptions?: ISubscription[];
  paymentType: PaymentTypes;
  paymentState: PaymentStates;
  premiumSubscription?: IProduct;
  _subscriptionType: string | SubscriptionType;
  subscriptionTypeMode: SubscriptionTypeModes;
  afterPaywallProducts?: IAditionalProduct[];
  purchasedProductId: string;
  // currentAfterPaywallProductId: string;
  analyticsSentSuccessfully: boolean;
}

export const PaymentStore = types
  .model('PaymentStore', {
    paymentApproved: false,
    isLoading: false,
    productId: '',
    initialDiscount: types.optional(types.string, ''),
    afterPaywallProductIds: types.array(types.string),
    currentAfterPaywallProductId: '',
    afterPaywallPurchasingProducts: types.optional(
      types.array(types.string),
      [],
    ),
  })
  .volatile(
    (): IPaymentStoreVolatile => ({
      products: undefined,
      subscriptionsProducts: undefined,
      subscriptions: undefined,
      premiumSubscription: undefined,
      paymentType: FirebaseService.paymentProviders[0] ?? PaymentTypes.PAYPRO,
      paymentState: PaymentStates.initial,
      _subscriptionType: '',
      subscriptionTypeMode: SubscriptionTypeModes.default,
      afterPaywallProducts: undefined,
      purchasedProductId: '',
      analyticsSentSuccessfully: false,
    }),
  )
  .views(self => ({
    get validSubscriptions() {
      return self.subscriptions?.filter(item => item.valid);
    },
  }))
  .views(self => ({
    get subscriptionsList():
      | Array<ISubscriptionWithIntervalDate | undefined>
      | undefined {
      return self.validSubscriptions?.map(currentSubscription => {
        const { platform, localProductId } = currentSubscription;
        const productsEntries =
          self.subscriptionsProducts &&
          Object.entries(self.subscriptionsProducts);

        const relatedProductEntries = productsEntries?.find(
          ([localId]) => localId === localProductId,
        );

        const relatedProduct: IProductDescription | undefined =
          relatedProductEntries?.[1]?.[platform as 'stripe' | 'paypal'];

        if (!relatedProduct) {
          return undefined;
        }

        const {
          interval,
          intervalCount,
          amount,
          currency,
          price_id: priceId,
        } = relatedProduct;

        return {
          ...currentSubscription,
          interval,
          intervalCount,
          amount,
          currency,
          priceId,
          title: formatIntervalTitle(interval),
          subtitle: formatIntervalPrice({
            amount,
            interval,
            intervalCount,
            currency,
          }),
        };
      });
    },
    get currentSubscription() {
      return self.validSubscriptions?.pop();
    },

    get productData() {
      return self.products?.[self.productId]?.[self.paymentType];
    },

    get stripeProductData() {
      return self.products?.[self.productId]?.[PaymentTypes.STRIPE];
    },

    get paypalProductData() {
      return self.products?.[self.productId]?.[PaymentTypes.PAYPAL];
    },

    get payproProductData() {
      return self.products?.[self.productId]?.[PaymentTypes.PAYPRO];
    },

    get paddleProductData() {
      return self.products?.[self.productId]?.[PaymentTypes.PADDLE];
    },

    getPayproProductDataById(id: string) {
      return self.products?.[id]?.[PaymentTypes.PAYPRO];
    },

    afterPaywallProductData(_id: string) {
      return self.afterPaywallProducts?.find(({ id }) => id === _id);
    },
  }))
  .views(self => ({
    get passAllAfterPaywalls() {
      if (
        self.currentAfterPaywallProductId ===
        self.afterPaywallProductIds[self.afterPaywallProductIds.length - 1]
      ) {
        return true;
      }
      return false;
    },

    get coupon() {
      return self.payproProductData?.coupon;
    },

    get stripePriceId() {
      return self.stripeProductData?.price_id;
    },

    get paypalPriceId() {
      return self.paypalProductData?.price_id;
    },

    get payproPriceId() {
      return self.payproProductData?.price_id;
    },

    payproProductPriceId(id: string) {
      return self.afterPaywallProductData(id)?.productId;
    },

    payproProductId(id: string) {
      return self.afterPaywallProductData(id)?.id;
    },

    get currentSubscriptionData(): ISubscriptionWithIntervalDate | undefined {
      return self.subscriptionsList?.[0];
    },

    get subscriptionType(): string {
      return typeof self._subscriptionType === 'string'
        ? self._subscriptionType
        : self._subscriptionType?.[self.subscriptionTypeMode] ??
            DEFAULT_SUBSCRIPTION_TYPE;
    },
  }))
  .views(self => ({
    get sortedProducts() {
      return (
        (self.products &&
          Object.entries(self.products)
            .filter(
              value =>
                !FirebaseService.hiddenProductIds?.[
                  self.subscriptionType
                ]?.includes(value[0]),
            )
            .sort(
              ([_, vA], [__, vB]) =>
                vA![self.paymentType]?.amount - vB![self.paymentType]?.amount,
            )) ||
        []
      );
    },

    get trialEndDate() {
      const data = self.productData;
      return data?.trial !== undefined && formatTrialDateEnd(data);
    },

    get trialAmountDate() {
      const data = self.productData;
      if (!data?.trial) {
        return;
      }
      const { interval, intervalCount } = data.trial;
      if (!interval) {
        return intervalCount;
      }
      return `${intervalCount} ${interval?.toLocaleLowerCase()}`;
    },

    intervalPrice(delimeter: string) {
      const data = self.productData;
      return data && formatIntervalPrice({ ...data, delimeter });
    },

    get renewsDate() {
      const { currentSubscriptionData } = self;
      return (
        currentSubscriptionData && formatRenewsDate(currentSubscriptionData)
      );
    },

    get menuSubscriptionTitle() {
      const { currentSubscriptionData } = self;
      return (
        currentSubscriptionData &&
        formatMenuSubscriptionTitle(currentSubscriptionData)
      );
    },

    get productPlans() {
      return formatProductsData(self.productData, FirebaseService.paywallType);
    },

    get isDiscountSubscriptions() {
      return self.subscriptionTypeMode === SubscriptionTypeModes.discount;
    },

    getPremiumUpgradeById(currentId: string) {
      return FirebaseService.premiumUpgradeIds[currentId];
    },

    getPayproProductDataById(id: string) {
      return self.products?.[id]?.[PaymentTypes.PAYPRO];
    },

    get upgradedPremiumSubscriptionPaypro() {
      return self.premiumSubscription?.paypro;
    },

    get cheapPayproProductId() {
      if (self.products) {
        const startId = Object.keys(self.products)[0];
        return Object.entries(self.products).reduce((acumId, value) => {
          const vId = value[0];
          if (
            self.products?.[vId]?.paypro.amount! <
            self.products?.[acumId]?.paypro.amount!
          ) {
            return vId;
          }
          return acumId;
        }, startId);
      }
      return FirebaseService.upgradeSubscriptionIds.cheap[0];
    },

    get currentAfterPaywallProductPos() {
      const pos = self.afterPaywallProductIds?.findIndex(
        id => id === self.currentAfterPaywallProductId,
      );
      return pos > -1 ? pos : 0;
    },
  }))
  .actions(() => ({
    getProducts: flow(function* (productIds: string[]) {
      const res = yield* toGenerator(SensesSDK.getProducts(productIds));
      return res.result;
    }),
  }))
  .actions(self => ({
    getAllSubscriptions: flow(function* () {
      self.isLoading = true;
      try {
        const res = yield* toGenerator(SensesSDK.getAllSubscriptions());
        if (Array.isArray(res.result)) {
          self.subscriptions = res.result;
          self.paymentApproved = res.result.some(x => x.valid);
        }
      } catch (error) {
        console.error('getAllSubscriptions error: ', error);
      } finally {
        self.isLoading = false;
      }
    }),

    getPaywallProducts: flow(function* () {
      self.isLoading = true;
      try {
        const paywallType = FirebaseService.paywallType;
        self._subscriptionType = FirebaseService.subscriptionType(paywallType);
        const subscriptionType = self.subscriptionType;
        const productIds = FirebaseService.productIds?.[subscriptionType];
        const selectedProductId =
          FirebaseService.selectedProductId?.[subscriptionType];
        if (selectedProductId && !productIds.includes(self.productId)) {
          self.productId = selectedProductId;
        }
        const res = yield* toGenerator(self.getProducts(productIds));
        self.products = res;
      } catch (error) {
        console.error('getPaywallProducts error: ', error);
      } finally {
        self.isLoading = false;
      }
    }),

    getSubscriptionProducts: flow(function* () {
      self.isLoading = true;
      try {
        const productIds = self.validSubscriptions!.map(
          sub => sub.localProductId,
        );

        const res = yield* toGenerator(self.getProducts(productIds));

        self.subscriptionsProducts = res;
      } catch (error) {
        console.error('getSubscriptionProducts error: ', error);
      } finally {
        self.isLoading = false;
      }
    }),

    selectNextAfterPaywallProductId() {
      const curPos = self.afterPaywallProductIds?.findIndex(
        id => id === self.currentAfterPaywallProductId,
      );
      const pos =
        curPos === -1
          ? 0
          : curPos + 1 > self.afterPaywallProductIds.length - 1
          ? 0
          : curPos + 1;
      self.currentAfterPaywallProductId = self.afterPaywallProductIds[pos];
    },

    setCurrentAfterPaywallProductByIndex(index: number) {
      if (index < self.afterPaywallProductIds.length) {
        self.currentAfterPaywallProductId = self.afterPaywallProductIds[index];
      }
    },
  }))
  .actions(self => ({
    cancelStripeSubscription: flow(function* (
      body: ICancelStripeSubscriptionRequest,
    ) {
      self.isLoading = true;
      try {
        yield* toGenerator(SensesSDK.cancelStripeSubscription(body));
        yield* toGenerator(self.getAllSubscriptions());
      } catch (error) {
        console.error('cancelStripeSubscription error: ', error);
      } finally {
        self.isLoading = false;
      }
    }),

    getPremiumSubscriptions: flow(function* () {
      try {
        yield* toGenerator(self.getAllSubscriptions());

        self.isLoading = true;

        const currentProductData = self.getPayproProductDataById(
          self.currentSubscriptionData?.localProductId ?? '',
        );

        const upgradeMatrix = currentProductData?.upgradeMatrix;

        if (!upgradeMatrix) {
          throw 'matrix not found';
        }

        const premiumId = upgradeMatrix[0].localProductId;

        const res = yield* toGenerator(self.getProducts([premiumId]));
        self.premiumSubscription = res[premiumId];
      } catch (error) {
        console.error('getPremiumSubscriptions error: ', error);
      } finally {
        self.isLoading = false;
      }
    }),

    createPayPalSubscription: flow(function* (deviceId: string) {
      self.isLoading = true;
      try {
        if (!self.paypalPriceId) {
          throw new Error('paypal price id is not loaded');
        }
        const response = yield* toGenerator(
          SensesSDK.paypalSubscriptionCreate({
            planId: self.paypalPriceId,
            deviceId,
            gaClientId: GA.clientId,
            urlParams: JSON.stringify(analitics.startUTMParams),
          }),
        );
        return response.result.id;
      } catch (error) {
        console.error('createPayPalSubscription error: ', error);
      } finally {
        self.isLoading = false;
      }
    }),

    createCardPaySubscription: flow(function* ({
      cardPay,
      userInfo,
      onSuccess,
      onError,
    }: ICreateStripeSubscription) {
      self.isLoading = true;
      self.paymentState = PaymentStates.stripeLoading;

      try {
        const paymentMethodId = yield* toGenerator(
          StripeService.getPaymentMethodWithCard(cardPay!),
        );
        const priceId = self.stripePriceId;
        if (!priceId) {
          throw new Error('stripe price id is not loaded');
        }
        const res = yield* toGenerator(
          SensesSDK.stripeSubscriptionCreate({
            paymentMethodId,
            priceId,
            ...userInfo,
            gaClientId: GA.clientId,
            urlParams: JSON.stringify(analitics.startUTMParams),
          }),
        );

        if (res) {
          self.paymentState = PaymentStates.success;
          self.paymentApproved = true;
          onSuccess();
        }
      } catch (error: any) {
        if (
          error?.params &&
          'statusCode' in error?.params &&
          error.params?.statusCode ===
            WebPaymentStatus.PREVIOUS_SUBSCRIPTION_NOT_CANCELLED
        ) {
          self.paymentApproved = true;
          onSuccess();
        } else {
          self.paymentState = PaymentStates.error;
          onError();
        }
        console.error('createCardPaySubscription error: ', error);
      } finally {
        self.isLoading = false;
      }
    }),

    confirmPaypalSubscription: flow(function* (subscriptionID?: string | null) {
      self.isLoading = true;
      try {
        const body = [];
        if (subscriptionID) {
          body.push(subscriptionID);
        }
        const { result } = yield* toGenerator(
          SensesSDK.confirmPaypalSubscription(body),
        );

        return result;
      } catch {
        return false;
      } finally {
        self.isLoading = false;
      }
    }),

    cancelSubscription: flow(function* (sub: ISubscriptionWithIntervalDate) {
      self.isLoading = true;
      const { priceId, originalTransactionId, platform } = sub;

      try {
        switch (platform) {
          case 'stripe':
            yield* toGenerator(
              SensesSDK.cancelStripeSubscription({
                priceId,
                cancelAtPeriodEnd: true,
              }),
            );
            break;
          case 'paypal':
            yield* toGenerator(
              SensesSDK.cancelPaypalSubscription(originalTransactionId),
            );
            break;
          case 'paypro':
            yield* toGenerator(
              SensesSDK.cancelPayproSubscription(originalTransactionId),
            );
            break;
          case 'paddle':
            yield* toGenerator(
              SensesSDK.cancelPaddleSubscription(originalTransactionId),
            );
            break;
          default:
            throw new Error('Wrong subscription platform');
        }

        self.getAllSubscriptions();
      } catch (error) {
        console.error('cancelSubscription error: ', error);
      } finally {
        self.isLoading = false;
      }
    }),

    payproPool: flow(function* () {
      self.isLoading = true;

      try {
        return yield* toGenerator(SensesSDK.payproPool(self.payproPriceId!));
      } finally {
        self.isLoading = false;
      }
    }),

    payproAdditionalProducts: flow(function* () {
      self.isLoading = true;
      try {
        const products = yield* toGenerator(SensesSDK.getAdditionalProduct());
        self.afterPaywallProductIds = cast(
          FirebaseService.afterPaywallProductIds,
        );
        if (
          self.afterPaywallProductIds.length > 0 &&
          !self.currentAfterPaywallProductId
        ) {
          self.currentAfterPaywallProductId = self.afterPaywallProductIds?.[0];
        }
        self.afterPaywallProducts = convertObjWithIdRecursive(products);
      } finally {
        self.isLoading = false;
      }
    }),

    payproUserAdditionalProducts: flow(function* () {
      self.isLoading = true;
      try {
        const products = yield* toGenerator(
          SensesSDK.getUserAdditionalProduct(),
        );
        self.afterPaywallProducts = convertObjWithIdRecursive(products);
      } finally {
        self.isLoading = false;
      }
    }),

    payproBuyAdditionalProducts: flow(function* (productId: string) {
      self.isLoading = true;
      try {
        const data = yield* toGenerator(
          SensesSDK.buyAdditionalProduct(productId),
        );
        if (data.result) {
          self.afterPaywallPurchasingProducts.push(productId);
        }
        return data;
      } finally {
        self.isLoading = false;
      }
    }),

    upgradeSubscription: flow(function* (
      oldProductId: string,
      newProductId: string,
    ) {
      self.isLoading = true;
      try {
        const data = yield* toGenerator(
          SensesSDK.upgradeSubscription(oldProductId, newProductId),
        );

        return data;
      } finally {
        self.isLoading = false;
      }
    }),

    setPaymentApproved() {
      self.paymentState = PaymentStates.success;
      self.paymentApproved = true;
    },

    setFinishLoadingPaymentApproved() {
      self.isLoading = false;
    },

    setStartLoadingPayment() {
      self.isLoading = false;
    },

    resetPaymentState() {
      self.paymentState = PaymentStates.initial;
    },

    setPaymentLoading() {
      self.paymentState = PaymentStates.loading;
    },

    onChangeProductId(id: string) {
      self.productId = id;
    },

    setPaymentType(type: PaymentTypes) {
      self.paymentType = type;
    },

    enableDiscount() {
      self.subscriptionTypeMode = SubscriptionTypeModes.discount;
      const subscriptionType = self.subscriptionType;
      const selectedProductId =
        FirebaseService.selectedProductId?.[subscriptionType];
      if (selectedProductId) {
        self.productId = selectedProductId;
      }
      self.getPaywallProducts();
    },

    setInitialDiscount(amount: string) {
      self.initialDiscount = amount;
    },

    setPurchasedProductId(id: string) {
      self.purchasedProductId = id;
    },

    sentAnalytics() {
      self.analyticsSentSuccessfully = true;
    },
  }));
