import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';

import { useMst } from 'stores/RootStore';

import { Amplitude, ANALYTICAL_EVENTS } from 'services/amplitude';
import { analitics } from 'services/analytics';
import { GA } from 'services/ga';
import { PixelServices, PixelTrackEvents } from 'services/pixelServices';
import { FirebaseService } from 'services/remoteConfig';

import { getPixelMarketingPrice } from 'utils/analytics';

import { useRelativeNavigation } from 'hooks/useRelativeNavigation';

import { APP_ROUTES } from 'navigations/Routs';

import { getPaymentTypesData, PaymentTypes } from 'constants/common';

import { PayproContainer } from 'components/PayproContainer/PayproContainer';

import { PaypalContainer } from './PaypalContainer/PaypalContainer';
import { PricesBlock } from './PricesBlock/PricesBlock';
import { StripeFormContainer } from './StripeFormContainer/StripeFormContainer';
import { purchaseEventPeriod, purchaseEventValue } from './utils';

import {
  Container,
  PaymentTypeCard,
  PaymentTypeWrapper,
  Title,
  TopSubtitle,
} from './PaymentSection.style';

interface Props {
  stripeButtonText?: string;
  showTitle?: boolean;
  cardLayout?: boolean;
  showPrices?: boolean;
}

export const PaymentSection = observer(
  ({
    showTitle = true,
    cardLayout = true,
    showPrices = false,
    stripeButtonText,
  }: Props) => {
    const { paymentStore, authStore } = useMst();
    const { push } = useRelativeNavigation();

    const isStripe = paymentStore.paymentType === PaymentTypes.STRIPE;
    const isPaypal = paymentStore.paymentType === PaymentTypes.PAYPAL;
    const isPaypro = paymentStore.paymentType === PaymentTypes.PAYPRO;

    const processPayment = () => {
      if (!paymentStore.productData) {
        return;
      }
      paymentStore.setPaymentApproved();
      paymentStore.setFinishLoadingPaymentApproved();

      if (!paymentStore.analyticsSentSuccessfully) {
        const { interval, intervalCount, amount, trial, currency, price_id } =
          paymentStore.productData;

        const pixelValue = getPixelMarketingPrice(paymentStore.productData);

        const pixelData = {
          value: pixelValue,
          currency: trial.currency,
        };
        PixelServices.track(PixelTrackEvents.PURCHASE, pixelData);
        PixelServices.pinterestTrack(PixelTrackEvents.PINTEREST_CHECKOUT, {
          ...pixelData,
          order_id: authStore.token,
          order_quantity: 1,
        });
        PixelServices.tiktokTrack(PixelTrackEvents.PURCHASE, pixelData);
        PixelServices.snapTrack(PixelTrackEvents.SNAP_PURCHASE, {
          currency: trial.currency,
          price: pixelValue,
        });

        Amplitude.logEvent(ANALYTICAL_EVENTS.PAYWALL_TYPE_SUBSCRIBE, {
          paymentType: paymentStore.paymentType,
        });

        const body = {
          platform: paymentStore.paymentType,
          price_id,
          period: purchaseEventPeriod(interval, intervalCount),
          value: purchaseEventValue(currency, amount),
          trial_period: purchaseEventPeriod(
            trial.interval,
            trial.intervalCount,
          ),
          trial_value: purchaseEventValue(trial.currency, trial.amount),
          email: authStore.email,
          ...analitics.startUTMParams,
        };

        GA.purchase(body);
        Amplitude.logEvent(ANALYTICAL_EVENTS.PAYWALL_PURCHASE_SUBSCRIBE, body);
      }

      paymentStore.sentAnalytics();
      paymentStore.setPurchasedProductId(paymentStore.productId);

      if (cardLayout) {
        if (FirebaseService.showAfterPaywall && isPaypro) {
          if (
            FirebaseService.showUpgradePaywall &&
            paymentStore.purchasedProductId !==
              FirebaseService.upgradeSubscriptionIds.expensive
          ) {
            push(APP_ROUTES.SALE);
          } else {
            push(APP_ROUTES.EXTRA);
          }
        } else {
          push(APP_ROUTES.SUCCESS);
        }
      }
    };

    const handlePaymentSuccess = () => {
      processPayment();
    };

    const handlePaymentWaiting = () => {
      Amplitude.logEvent(ANALYTICAL_EVENTS.PAYWALL_WAITING_PAYMENT);
      processPayment();
    };

    const handlePaymentError = () =>
      paymentStore.setFinishLoadingPaymentApproved();

    const handleCancelPayment = () => paymentStore.resetPaymentState();

    const paymentTypesData = useMemo(
      () => getPaymentTypesData(FirebaseService.paymentProviders),
      [FirebaseService.paymentProviders],
    );

    const showPaymentSelect = paymentTypesData.length > 1;

    useEffect(() => {
      paymentStore.payproUserAdditionalProducts();
    }, []);

    return (
      <Container id="payment-section">
        {showTitle && (
          <>
            <Title>Payment Method</Title>
            <TopSubtitle>Start your 3-day trial with:</TopSubtitle>
          </>
        )}
        {showPaymentSelect && (
          <PaymentTypeWrapper>
            {paymentTypesData.map(data => (
              <PaymentTypeCard
                key={data.value}
                onClick={() => paymentStore.setPaymentType(data.value)}
                isActive={paymentStore.paymentType === data.value}>
                <img src={data.cover} />
              </PaymentTypeCard>
            ))}
          </PaymentTypeWrapper>
        )}
        {showPrices && <PricesBlock />}
        {isStripe && (
          <StripeFormContainer
            cardLayout={cardLayout}
            buttonText={stripeButtonText}
            handlePaymentError={handlePaymentError}
            handlePaymentSuccess={handlePaymentSuccess}
          />
        )}
        {isPaypal && (
          <PaypalContainer
            handleCancelPayment={handleCancelPayment}
            handlePaymentError={handlePaymentError}
            handlePaymentSuccess={handlePaymentSuccess}
          />
        )}
        {isPaypro && (
          <PayproContainer
            config={{
              email: authStore.email,
              accountId: authStore.accountId!,
              payproPriceId: paymentStore.payproPriceId!,
              currency: paymentStore.productData?.currency!,
              coupon: paymentStore.coupon,
              startUrl: JSON.stringify(analitics.startHref),
              urlParams: JSON.stringify(analitics.startParams),
              deviceParams: JSON.stringify({
                ...analitics.deviceParams,
                ga_client_id: GA.clientId,
              }),
              startPage: FirebaseService.page,
            }}
            handlePaymentWaiting={handlePaymentWaiting}
            handlePaymentSuccess={handlePaymentSuccess}
          />
        )}
      </Container>
    );
  },
);
